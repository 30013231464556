<template>
  <div class="route-page">
    <el-scrollbar v-if="previewData" v-cloak style="height: 100vh">
      <div class="page-content flex-justify-center">
        <div class="page-main w-full flex-col" style="max-width: 677px">
          <h1 class="title">{{ previewData.title }}</h1>
          <div class="info">
            <span v-if="previewData.source" class="tag">{{
              previewData.source.name
            }}</span>
            <span class="tag time"
              ><time :datetime="previewData.post_time">{{
                previewData.post_time
              }}</time></span
            >
          </div>
          <div class="rich-text w-full" v-html="previewData.content"></div>
        </div>
      </div>

      <el-backtop target=".route-page .el-scrollbar__wrap"></el-backtop>
      <div class="sync-button">
        <el-button size="small" type="primary" @click="clickSync"
          >同步该文章</el-button
        >
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { getPreviewData } from '../../article-admin/api/base'
import { setTitle, formatDate } from '../../../base/utils/tool'

export default {
  data() {
    return {
      previewData: null,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const { id, w } = this.$route.params
      getPreviewData(id, w === 'y' ? 1 : 0)
        .then((res) => {
          res.data.content = res.data.content
            .replace(/\\n/gi, '')
            .replace(/<\/section>\s+<section/gi, '</section><section')
            .replace(/><\/section>/gi, '><br/></section>')
            .replace(/<img/gi, '<img class="max-width"')
            .replace(/<video/gi, '<video class="max-width"')
          // .replace(/<section/gi, '<section class="overflow-x"')
          // .replace(/<p/gi, '<p class="overflow-x"')

          this.previewData = res.data
          this.previewData.post_time = formatDate(new Date(), 'YYYY-MM-DD H:m')

          setTitle(this.previewData.title)
        })
        .catch((err) => {})
    },
    clickSync() {
      if (!this.previewData) return
      window.syncPost({
        title: this.previewData.title,
        desc: this.previewData.summary || this.previewData.title,
        content: this.previewData.content,
        thumb:
          this.previewData.images &&
          this.previewData.images.length &&
          this.previewData.images[0] &&
          this.previewData.images[0].type === 'image' &&
          this.previewData.images[0].url
            ? this.previewData.images[0].url
            : '' // 封面，如果有
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../scss/common';

.route-page {
  background-color: white;
  height: 100vh;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.9);

  .page-main {
    justify-content: flex-start;
  }

  .page-content {
    padding: $space;
    align-items: flex-start;
  }

  .title {
    margin-bottom: 14px;
    font-size: 22px;
    font-weight: normal;
  }

  .info {
    margin-bottom: 22px;
    font-size: 15px;

    .tag {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .tag.time {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  >>> .rich_media_content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    word-wrap: break-word !important;
  }

  >>> .rich_media_content {
    padding: 0;
    margin: 0;
    /*font-size:0;*/
    font-size: 17px;
  }

  .sync-button {
    position: fixed;
    left: 0;
    right: 0;
    top: 68px;
    width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
